.song-likes {
  display: flex;
  align-items: center;

  font-size: 20px;

  svg {
    font-size: 30px;
    margin-left: 10px;
  }
}
