@import "./../../../../styles/variables";

.tv-mode__buttons {
  opacity: 0;

  display: flex;

  position: fixed;
  right: 0;
  top: 50%;

  background-color: rgba(0, 0, 0, 0.5);
  padding: 120px;

  z-index: 110;

  &:hover {
    opacity: 1;
  }

  &-back {
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: bold;
    text-decoration: none;
    color: $color-black;

    padding: 5px 15px;
    margin-right: 15px;

    outline: none;
    border: none;
    border-radius: 33px;
    background-color: $color-white;
    cursor: pointer;

    svg {
      margin-right: 5px;

      font-size: inherit;
    }
  }

  &-fullscreen {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 5px 15px;

    border-radius: 33px;
    background-color: $color-white;

    cursor: pointer;

    svg {
      font-size: inherit;
    }
  }
}
