.footer {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
  width: 100%;

  &-content {
    display: flex;

    justify-content: center;
    align-items: center;
  }

  &-rights {
    margin-right: 20px;
  }

  a {
    color: #fff;
    margin-left: 20px;
  }
}
