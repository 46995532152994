@import "./../../styles/variables";

.fullscreen-logo-enter {
  opacity: 0;
}
.fullscreen-logo-enter-active {
  opacity: 1;
  transition: opacity 0.6s;
}
.fullscreen-logo-exit {
  opacity: 1;
}
.fullscreen-logo-exit-active {
  opacity: 0;
  transition: opacity 0.6s;
}

.tv-mode {
  background-color: #000000;

  width: 100vw;
  height: 100vh;

  &__video {
    display: flex;

    &-iframe {
      width: 100vw;
      height: 100vh;
    }
  }
}
