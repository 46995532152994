@import "../../styles/variables.scss";

.page-dataDeletion {
  color: rgb(255, 255, 255);

  min-height: calc(100vh - 64px);

  font-weight: 300;

  a {
    color: white;
  }

  padding: 30px 100px;

  @media screen and (max-width: $mobileWidth) {
    padding-left: 10px;
    padding-right: 10px;
  }
  @media screen and (max-width: $tabletWidth) {
    padding-left: 10px;
    padding-right: 10px;
  }
}
