@import "../../../../styles/variables";

.songs-list__all {
  width: 100%;
  padding: 0;
  color: #ffffff;

  .songs-list {
    &__item {
      background-color: $color-blue-70;

      &:nth-child(2n) {
        background-color: $color-blue-60;
      }
    }
  }
}
