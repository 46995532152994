@import "./../../styles/variables";

.page-login {
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("./../../assets/bg-login-2.jpg");

  background-position: center;
  background-size: cover;

  background-color: rgba(0, 0, 0, 0.1);

  .modal {
    width: 300px;
    padding: 30px;
    border-radius: 20px;
    background-color: #fff;

    display: flex;
    flex-direction: column;

    &-logo {
      width: 100%;

      margin: 20px 0 40px;
    }

    &-title {
      text-align: center;

      font-size: 26px;

      padding-bottom: 10px;
    }

    &-form {
      display: flex;
      flex-direction: column;

      &-loading {
        display: flex;
        justify-content: center;
      }

      button {
        cursor: pointer;

        padding: 10px;
        margin-top: 20px;

        border-radius: 20px;
        border: none;
        background-color: $color-red;
        color: #fff;

        font-family: inherit;
        font-size: 16px;
        font-weight: 500;

        &:hover {
          background-color: $color-red-hover;
        }
      }
    }
  }
}
