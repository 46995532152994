@import "../../../../styles/variables";

.current-song {
  display: flex;
  gap: 40px;

  padding: 20px;
  margin-bottom: 24px;
  border-radius: 20px;

  width: 100%;

  background-color: $color-blue-60;

  @media screen and (max-width: $tabletWidth) {
    gap: 30px;
  }
  @media screen and (max-width: $mobileWidth) {
    gap: 20px;
  }

  img {
    width: 20%;
    min-width: 170px;
    max-width: 350px;
    max-height: 200px;

    border-radius: 20px;
    object-fit: cover;
    object-position: center;

    @media screen and (max-width: $tabletWidth) {
      width: 40%;
      min-width: 80px;
    }
  }

  &__info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 70%;
  }

  &__title {
    display: block;
    margin-bottom: 20px;

    font-weight: 400;

    font-size: 30px;

    @media screen and (max-width: $tabletWidth) {
      font-size: 20px;
    }
    @media screen and (max-width: $mobileWidth) {
      font-size: 16px;
    }
  }

  &__subtitle {
    display: block;

    font-weight: 300;
    font-size: 20px;
  }

  &__controls {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 10px;

    &-play {
      display: flex;
      justify-content: center;
      align-items: center;

      margin: 0 15px;

      cursor: pointer;
      background-color: $color-red;
      border-radius: 50%;

      svg {
        font-size: 50px;

        padding: 7px;

        @media screen and (max-width: $tabletWidth) {
          font-size: 40px;
        }
      }
    }

    &-next {
      display: flex;
      cursor: pointer;

      svg {
        font-size: 50px;

        @media screen and (max-width: $tabletWidth) {
          font-size: 40px;
        }
      }

      span {
        width: 50px !important;
        height: 50px !important;
      }
    }
  }

  &__progress-bar {
    &-time-details {
      display: flex;
      justify-content: space-between;
      margin-bottom: 7px;

      font-size: 16px;
      color: $color-light-gray;
    }

    &-outer {
      height: 8px;
      width: 100%;
      background-color: #1c2224;
      border-radius: 34px;
    }

    &-inner {
      height: 100%;
      width: 1%;
      background-color: $progress-bar-color;
      border-radius: inherit;
    }
  }
}
