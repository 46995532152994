@import "../../styles/variables.scss";

.searchBar {
  display: flex;

  background-color: $color-blue-90;

  overflow: hidden;

  margin-bottom: 20px;

  width: 100%;

  &-wrap {
    width: 100%;
    border-radius: 30px;
    border: 2px solid $color-blue-40;
  }

  .searchBar-search2 {
    &__song {
      background-color: $color-blue-70;

      .songs-list__item-info__thumbnail {
        min-width: 40px;
        height: 40px;
      }
    }

    &__back {
      white-space: nowrap;
      color: #ffffff;
      border: 1px solid #ffffff;

      &:hover {
        background-color: $color-white-hover;
      }
    }

    &__divider {
      margin: 12px;
      border-color: rgb(97, 97, 97);
    }
  }

  .searchBar-menu {
    font-size: 36px;

    margin: 10px 10px 0 0;

    display: none;
    @media screen and (max-width: $muiMobileWidth) {
      display: initial;
    }
  }

  &-field {
    background-color: white;
    position: relative;
    border-radius: 30px;

    &__search-icon {
      position: absolute;

      display: flex;
      align-items: center;
      padding: 10px 10px 10px 15px;
      height: 100%;
      pointer-events: none;

      color: $color-blue-20;
    }

    &__input {
      padding: 10px 50px;
      width: 100%;

      .MuiInputBase-input {
        color: $color-blue-40;
        font-size: 18px;
      }

      &:empty {
        border: 1px solid red;
      }
    }

    &__clear-icon {
      position: absolute;
      right: 0;
      top: 0;

      display: flex;
      align-items: center;
      padding: 10px 15px 10px 10px;
      height: 100%;
      cursor: pointer;

      color: $color-blue-20;
    }
  }

  &-results {
    display: flex;
    justify-content: center;

    overflow: hidden;
    border-radius: 30px;

    height: 0;

    transition: height ease-in-out 300ms;

    &__open {
      height: 200px;

      &-full {
        height: 770px;

        @media screen and (max-width: $mobileWidth) {
          height: 610px;
        }
      }
    }

    &__empty {
      display: flex;
      align-items: center;

      color: $color-blue-20;
    }

    &__list {
      color: #ffffff;

      width: 100%;
      padding: 15px;

      @media screen and (max-width: $mobileWidth) {
        padding: 10px;
      }
    }

    &__loading {
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
