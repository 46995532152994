@import "./styles/variables";

body {
  margin: 0;
  font-family: "Roboto", sans-serif;

  background-color: $color-blue-90;
}

* {
  box-sizing: border-box;
}

.center-loading-block {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
