@import "./../../styles/variables";

.songs-list__item-outer-block {
  border-radius: 40px;
  margin-bottom: 10px;

  &.grab {
    cursor: grab;
    padding: 0;
    list-style-type: none;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.songs-list__item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  padding: 5px 15px 5px 5px;
  border-radius: 40px;

  &-info {
    display: flex;
    align-items: center;

    padding-right: 20px;

    &__thumbnail {
      min-width: 55px;
      height: 55px;

      @media screen and (max-width: $mobileWidth) {
        min-width: 40px;
        height: 40px;
      }

      background-position: center;
      background-size: cover;
      border-radius: 100%;

      margin-right: 10px;
    }

    &__title {
      color: white;
      text-decoration: none;

      max-height: 75px;
      overflow: hidden;

      @media screen and (max-width: $mobileWidth) {
        font-size: 14px;
      }

      font-size: 16px;
      font-weight: 400;
    }
  }

  &-details {
    flex-basis: 25%;

    font-size: 18px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    &__duration {
      display: flex;
      align-items: center;
    }

    &__views {
      display: flex;
      align-items: center;

      svg {
        margin-right: 10px;
      }
    }
  }

  &-config {
    flex-basis: 15%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &__votes {
      display: flex;
      align-items: center;
      color: #ffffff;

      margin-left: 20px;
      @media screen and (max-width: $mobileWidth) {
        margin-left: 10px;
      }

      &-number {
        margin-right: 10px;
      }

      svg {
        cursor: pointer;
      }
    }

    &__views {
      display: flex;
      align-items: center;
      color: #979797;
      font-size: 14px;
      font-weight: 300;

      &-number {
        margin-right: 10px;
      }

      svg {
        font-size: 20px;
      }
    }

    &__edit {
      cursor: pointer;
      color: #9898a0;

      display: flex;
      align-items: center;

      margin-left: 20px;
      @media screen and (max-width: $tabletWidth) {
        margin-left: 10px;
      }
    }

    &__delete {
      cursor: pointer;
      color: #9898a0;

      display: flex;
      align-items: center;

      margin-left: 20px;
      @media screen and (max-width: $tabletWidth) {
        margin-left: 10px;
      }
    }
  }
}
