@import "../../../../styles/variables";

.full-screen-logo {
  position: fixed;
  inset: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: #ffffff;

  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./../../../../assets/bg-tvmode.jpg");

  background-position: center;
  background-size: cover;

  z-index: 100;

  img {
    max-width: 40vw;
    max-height: 40vh;
  }

  &-error {
    color: #ffffff;
    font-weight: 300;
    margin-top: 35px;
  }
}
