.fallback-page {
  display: flex;
  flex-direction: column;

  .loading {
    margin: auto;
  }

  &__no-items {
    text-align: center;
  }
}
