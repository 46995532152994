$color-white: #ffffff;
$color-white-hover: #e0e0e0;

$color-black: #000000;

$color-red: #ca253e;
$color-red-hover: #b21e35;

$color-blue-20: #6d787e;
$color-blue-40: #4a5257;
$color-blue-50: #353b3f;
$color-blue-60: #2b3236;
$color-blue-70: #21272a;
$color-blue-90: #131b1f;

$color-light-gray: #959595;
$color-dark-gray: #3a3d41;

$progress-bar-color: #acacac;

$party-code-bg-color: #5c6167;

$mobileWidth: 480px;
$muiMobileWidth: 600px;
$tabletWidth: 900px;
