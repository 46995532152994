@import "../../styles/variables.scss";

.page-admin {
  display: flex;
  min-height: 100vh;

  &__content {
    color: white;
    flex-grow: 1;

    padding: 20px;

    @media screen and (max-width: $mobileWidth) {
      padding: 10px;
    }

    // needed for css hack
    min-width: 0;
  }
}
