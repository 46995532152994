@import "../../../../styles/variables.scss";

.sidebar {
  background: none;

  &-drawer {
    background: none;

    .MuiPaper-root {
      background: none;
      border: none;
    }
  }

  &-item {
    &-active {
      background-color: #eaeaea;
    }
  }

  &-items {
    height: 100%;
    background-color: white;
    border-radius: 10px;

    margin: 10px;
    @media screen and (max-width: $muiMobileWidth) {
      margin: 0;
    }

    &__logo {
      width: 100%;
      padding: 30px;
    }
  }
}
