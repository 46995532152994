@import "../../styles/variables.scss";

.page-vote {
  padding: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;

  min-height: calc(100vh - 64px);

  @media screen and (max-width: $mobileWidth) {
    padding: 10px;
  }

  &__no-items {
    color: white;
  }

  .follow-us {
    cursor: pointer;
    margin: 20px;
    width: 50vw;
    height: 14vw;

    @media screen and (min-width: $tabletWidth) {
      width: 355px;
      height: 100px;
    }
  }
}
