@import "../../../styles/variables.scss";

.searchBar-results__list {
  .songs-list__item {
    background-color: $color-blue-60;

    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    .songs-list__item__config__add-button {
      white-space: nowrap;
      color: $color-blue-90;
      background-color: #ffffff;

      margin-left: 20px;
      @media screen and (max-width: $mobileWidth) {
        margin-left: 10px;
      }

      &:hover {
        background-color: $color-white-hover;
      }
    }
  }
}
